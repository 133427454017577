import '@/styles/globals.css'
import type { AppProps } from 'next/app'
import { datadogRum } from '@datadog/browser-rum';
import { FlagsmithProvider } from 'flagsmith-es/react';
import flagsmith from 'flagsmith-es/isomorphic';
import { IState } from 'flagsmith-es/types';

const flagsmithEnv = "2t87ZcngrYs8c9mGUnwsn2";

const flagsmithOptions = {
  environmentID: flagsmithEnv,
  enableLogs: false,
  realtime: typeof window!== 'undefined'?true:false,
  datadogRum: {
    client: datadogRum,
    trackTraits: true,
  },
}
export default function App({ Component, pageProps, flagsmithState }: AppProps & {flagsmithState: IState}) {
  
  if (process.env.NODE_ENV==='production') {
    datadogRum.init({
        applicationId: 'c958a017-3bcd-4122-a381-66d9ab3b2b0a',
        clientToken: 'pub78077bae9f97fe74929ceeaeb7d5a610',
        site: 'datadoghq.com',
        service:'carlesjavierre',
        env: "prod",
        version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel:'mask-user-input',
        enableExperimentalFeatures: ['feature_flags'],
    });
        
    datadogRum.startSessionReplayRecording();
  }

  return (
    <FlagsmithProvider
        serverState={flagsmithState as IState}
        options={flagsmithOptions}
        flagsmith={flagsmith}>
        <Component {...pageProps} />
    </FlagsmithProvider>
  );

}


App.getInitialProps = async () => {
  await flagsmith.init(flagsmithOptions);
  return { flagsmithState: flagsmith.getState() }
}